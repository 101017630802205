<template>

<div>

    <template v-if="format=='block'">
   
        <div  :style="{backgroundColor: backcolour}" style="text-align:center;padding-top:2px;border-radius:10px 10px 0px 0px;"><a :href="'/bookmakerloader.html?bkm=' + row.Description + '&country=' + $store.state.country" target="_blank" ><span :class="'b' + row.Description"></span></a></div>
            <div  class="align-middle">
                        <div> 
                            <div class="qmediumboldfont qwhitetext" :style="{backgroundColor: backcolour}" style="text-align:center;border-bottom: 1px solid #00dce2;" v-html="row.BanTitle"></div>
                            <div class="qsupersmallfont qwhitetext" :style="{backgroundColor: backcolour}" style="border-radius:0px 0px 10px 10px; padding: 5px;height:90px;color:white" v-html="row.BanDesc"></div>
                        </div>
                        <div  style="position:relative; top: -25px; left:5px" ><begambleaware :tandcs="tandcs"></begambleaware></div>
                        <div class="grid-x">
                        <div class="cell auto"></div>
                        <div class="cell shrink" style="position:relative; top: -25px">
                            <actionbutton :bookmaker="row.Description"></actionbutton>
                        </div>
                        <div class="cell auto"></div>
            </div>
        </div> 
      
    </template>

    <template v-if="format=='smallpromo'">

        <div class="grid-x" style="padding: 6px;height:62px" :style="{backgroundColor: backcolour}">
            <div class="cell shrink"><a :href="'/bookmakerloader.html?bkm=' + row.Description + '&country=' + $store.state.country" target="_blank" ><span :class="'b' + row.Description"></span></a></div>
    
            <div class="cell auto">
               
                <div class="grid-y">
                    <div class="grid-x" >
                        <div class="cell small-9 qmediumboldfont qwhitetext" :style="{backgroundColor: backcolour}"  v-html="row.BanTitle"></div>
                        <div class="cell small-3 qnewplayers qsupersmallfont">{{langConv('New Customer')}}</div>
                        
                    </div>
                    <div class="grid-x grid-padding-x" >
                        <div class="cell small-9 qsupersmallfont qwhitetext" :style="{backgroundColor: backcolour}" style="color:white;margin-top:-4px" v-html="row.BanDesc"></div>
                        <div class="cell small-3" style="padding-top:3px"><actionbutton :bookmaker="row.Description" buttontext="Go" ></actionbutton></div>
                    </div>
                </div>
           
            </div>
                    
        </div> 

            <!-- <div class="qsmallfont"> foot notes</div> -->
        
    
    </template>




</div>

</template>

<script>

import actionbutton from '/src/components/components/actionbutton.vue'
import begambleaware from '/src/components/adverts/begambleaware.vue'
    
    export default {
        name: "singleadverts", 
        data: function data() {
                return {
                
                }
         },
        components: {
            actionbutton,
            begambleaware
        },

        mounted: function mounted() {
          
        },
    
        props: {
            row: Object,
            backcolour: { type: String, default: 'white'},
            tandcs: {type: Boolean, default: true},
            format: {type: String, default: 'block'},


        }
    }





</script>



    





    